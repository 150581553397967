








































































































































































































































































import Vue from "vue";
import SubHeader from "@/views/layout/header/SubHeader.vue";
import { Component, Ref } from "vue-property-decorator";
import TextFloating from "@/components/input/TextFloating.vue";
import FileInput from "@/components/input/FileInput.vue";
import ListImageModel from "@/models/createTicket/ListImageModel";
import api from "@/api";
// import TicketModel from "@/models/TicketModel";
import MachineModel from "@/models/MachineModel";
import CategoryModel from "@/models/CategoryModel";
import UrgencyModel from "@/models/MachineModel";
import { BContainer, BRow, BCol } from "bootstrap-vue";
@Component({
  components: {
    BRow,
    BContainer,
    BCol,
    SubHeader,
    TextFloating,
    FileInput,
  },
})
export default class CreateTicket extends Vue {
  mounted() {
    // this.category = this.listCategory[0].name;
    this.loadCateogry();
  }
  titlePage = "New Ticket";
  subTitlePage = "Please Select Category";
  infoStep = "Choose category you want to report";
  step = "1";
  file = null;
  deskripsi = null;
  displayCategory = true;
  displayUploadTicket = false;
  displayDescription = false;
  isActive = false;
  isActiveMachine = false;
  isActiveDesc = false;
  isActiveLevel = false;
  subject: string | null = null;
  category: string | null = null;
  subCategory: string | null = null;
  statusCategory = false;
  // listCategory = [
  //   {
  //     name: "Pengajuan perbaikan",
  //     id: "1",
  //     name_image: [require("@/assets/img/category-1.svg")],
  //     sub_category: [
  //       {
  //         name_sub: "Sub Category 1",
  //         id_sub: "1",
  //       },
  //       {
  //         name_sub: "Sub Category 2",
  //         id_sub: "2",
  //       },
  //       {
  //         name_sub: "Sub Category 3",
  //         id_sub: "3",
  //       },
  //     ],
  //   },

  //   {
  //     name: "Store",
  //     id: "2",
  //     name_image: [require("@/assets/img/category-2.svg")],
  //     sub_category: [
  //       {
  //         name_sub: "Sub Category 4",
  //         id_sub: "1",
  //       },
  //       {
  //         name_sub: "Sub Category 5",
  //         id_sub: "2",
  //       },
  //       {
  //         name_sub: "Sub Category 6",
  //         id_sub: "3",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Preventif",
  //     id: "3",
  //     name_image: [require("@/assets/img/category-3.svg")],
  //     sub_category: [
  //       {
  //         name_sub: "Sub Category 7",
  //         id_sub: "1",
  //       },
  //       {
  //         name_sub: "Sub Category 8",
  //         id_sub: "2",
  //       },
  //       {
  //         name_sub: "Sub Category 9",
  //         id_sub: "3",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Project",
  //     id: "4",
  //     name_image: [require("@/assets/img/category-4.svg")],
  //     sub_category: [
  //       {
  //         name_sub: "Sub Category 1",
  //         id_sub: "1",
  //       },
  //       {
  //         name_sub: "Sub Category 2",
  //         id_sub: "2",
  //       },
  //       {
  //         name_sub: "Sub Category 3",
  //         id_sub: "3",
  //       },
  //     ],
  //   },
  // ];
  selected = null;
  selectedLevel = null;
  options = [
    { value: null, text: "Select Machine" },
    { value: "a", text: "This is First option" },
    { value: "b", text: "Selected Option" },
    { value: "d", text: "This one is disabled", disabled: true },
  ];
  level = [
    { value: null, text: "Urgency Level" },
    { value: "a", text: "This is First option" },
    { value: "b", text: "Selected Option" },
    { value: "d", text: "This one is disabled", disabled: true },
  ];

  @Ref() fileImg!: FileInput;
  @Ref() fileInputan!: HTMLElement;
  @Ref() categorySelect!: HTMLElement;
  listImage: ListImageModel[] = [];
  listMachine: MachineModel[] = [];
  listCategory: CategoryModel[] = [];
  listUrgency: UrgencyModel[] = [];
  // ticket: TicketModel = new TicketModel();

  loadCateogry() {
    return new Promise((resolve, reject) => {
      api.options
        .getCategories()
        .then((response) => {
          if (response.data.code === 200) {
            this.listCategory = [...response.data.data];
            resolve(response);
          }
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  }
  chooseImg() {
    if (this.fileImg) {
      this.fileImg.onElementClick();
    }
  }
  chooseCategory(item: string) {
    this.category = item;
  }
  cekSubject() {
    if (this.subject !== "") {
      this.isActive = true;
    } else if (this.subject === "") {
      this.isActive = false;
    }
  }
  cekSelect() {
    if (this.selected !== null) {
      this.isActiveMachine = true;
    } else if (this.selected === null) {
      this.isActiveMachine = false;
    }
  }
  cekDesc() {
    if (this.deskripsi !== "") {
      this.isActiveDesc = true;
    } else if (this.deskripsi === "") {
      this.isActiveDesc = false;
    }
  }
  cekLevel() {
    if (this.selectedLevel !== null) {
      this.isActiveLevel = true;
    } else if (this.selectedLevel === null) {
      this.isActiveLevel = false;
    }
  }

  addUploadImgRow() {
    const images = new ListImageModel();
    this.listImage.push(images);
  }
  removeUploadRow(i: number) {
    this.listImage.splice(i, 1);
  }
  submitCategory() {
    this.displayCategory = false;
    this.displayUploadTicket = true;
    this.displayDescription = false;
    this.step = "2";
    this.statusCategory = true;
    this.subTitlePage = "";
  }
  uploadTicket() {
    this.displayCategory = false;
    this.displayUploadTicket = false;
    this.displayDescription = true;
    this.step = "3";
    this.statusCategory = true;
  }
  cancelCategory() {
    this.$router.go(-1);
  }
  cancelUpload() {
    this.displayCategory = true;
    this.displayUploadTicket = false;
    this.displayDescription = false;
    this.step = "1";
    this.statusCategory = false;
    this.subTitlePage = "Please Select Category";
    // this.category = this.listCategory[0].name;
  }
  cancelDeskripsi() {
    this.displayCategory = false;
    this.displayUploadTicket = true;
    this.displayDescription = false;
    this.step = "2";
    this.statusCategory = true;
  }
  onElementClick() {
    if (this.fileInputan) {
      this.fileInputan.click();
    }
  }
  submitDeskripsi() {
    this.$router.push({ name: "ticket" });
  }
  getImgUrl(name_image: string) {
    var images = require.context("@/assets/", false, /\.svg$/);
    return images("img/" + name_image);
  }
}
